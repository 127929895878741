<template>
  <div class="container" id="processingFormTemplates_create">
    <h1 v-html="$t('processing_form_template_create_title')"></h1>
    <v-form @submit.prevent="onSubmit()">
      <v-row>
        <v-text-field
          id="dairyIdent"
          v-model="processingFormTemplate.dairy.ident"
          type="number"
          disabled
          persistent-hint
          placeholder="dairyIdent"
          :label="$t('processing_form_template_create_dairyMbhid')"
          :description="$t('processing_form_template_create_dairyMbhid_description')"
        />
        <DbmDatePicker v-model="processingFormTemplate.validFrom" :dateLabel="$t('processing_form_template_create_validFrom')" :required="true" />
        <v-select
          id="formPeriod"
          required
          :items="formPeriods"
          :label="$t('processing_form_template_create_formPeriod')"
          :hint="$t('processing_form_template_create_formPeriod_description')"
          persistent-hint
          item-value="id"
          :item-title="this.$getLangKey()"
          v-model="processingFormTemplate.formPeriod.id"
        />
      </v-row>
      <div class="aligned-buttons">
        <v-btn color="secondary" class="button-margin-left shadow-none" @click="goBack()"><span v-html="$t('back')" /></v-btn>
        <v-btn color="primary" class="button-margin-left shadow-none" type="submit"
          ><span v-html="$t('processing_form_template_create_button_submit')"
        /></v-btn>
      </div>
    </v-form>
  </div>
</template>
<script lang="ts">
import { showError } from '@/services/axios'
import { apiURL } from '@/main'
import DbmDatePicker from '@/components/dbmDatePicker.vue'
import moment from 'moment'
const processingFormTemplateMinimal = {
  dairy: {
    id: 123,
    ident: 1
  },
  formType: {
    id: 1
  },
  formPeriod: {
    id: 1
  },
  validFrom: moment().format('YYYY-MM-01'),
  copies: 0
}

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'processingFormTemplate_create',
  data() {
    return {
      processingFormTemplate: processingFormTemplateMinimal,
      formPeriods: []
    }
  },
  components: {
    DbmDatePicker: DbmDatePicker
  },
  methods: {
    async onSubmit() {
      try {
        const result = await this.axios.post(
          apiURL + '/processingFormTemplates',
          { ...this.processingFormTemplate },
          { headers: { 'Content-Type': 'application/json' } }
        )
        this.$router
          .push({
            name: 'processingFormTemplates_update',
            params: { id: result.data.id, tab: 'overview' }
          })
          .catch(() => {
            /* duplicated route */
          })
      } catch (e) {
        showError(e)
      }
    },
    goBack() {
      this.$router.go(-1)
    }
  },
  async mounted() {
    this.processingFormTemplate.dairy.id = this.$route.query.id
    this.processingFormTemplate.dairy.ident = this.$route.query.dairyIdent
    try {
      const response = await this.axios.get(apiURL + '/processingFormTemplates/formPeriods', {
        headers: { Accept: 'application/json' }
      })
      const data = response.data
      this.formPeriods = data
      this.processingFormTemplate.formPeriod.id = this.formPeriods[0].id
    } catch (e) {
      showError(e)
    }
  }
})
</script>
